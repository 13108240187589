import axios from "axios";
import { REACT_APP_API, brandsEndpoint } from "../consts/consts";

export const getAllBrands = (token) => async (dispatch) => {
    /* dispatch({ type: USER_SIGNIN_REQUEST, payload: { username, password } }) */
    try {
      console.log("Intento obtener marcas",REACT_APP_API)
      const { data } = await axios.get(`${REACT_APP_API}brands`,{
        headers: {
          "x-access-token": token,
        }
      });
      dispatch({ type: "GET_ALL_BRANDS", payload: data });
      // localStorage.setItem("userInfo", JSON.stringify(data.login));
    } catch (err) {
      alert(
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message
      );
    }
  };

  export const brandAdd = (brand, token) => async (dispatch) => {
    console.log("agregando",brand);
    try {
      const { data } = await axios.post(`${REACT_APP_API}brands/add`, brand, {
        headers: {
          "x-access-token": token,
        }
      });
      dispatch({ type: "BRAND_ADMIN_ADD", payload: data });
      localStorage.setItem("brandAdded",true)
    } catch (err) {
      localStorage.setItem("brandAdded",err.response.data.message)
      console.log(
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message
      );
    }
  };

  export const getBrandAdmin = (id,token) => async (dispatch) => {
    /* dispatch({ type: USER_SIGNIN_REQUEST, payload: { username, password } }) */
    try {
  /*     console.log(`${REACT_APP_API}/products/` + id); */
      const { data } = await axios.get(`${REACT_APP_API}brands/` + id, {
        headers: {
          "x-access-token": token,
        }
      });
/*       console.log(data) */
      dispatch({ type: "BRAND_ADMIN_GET", payload: data });
    } catch (err) {
      console.log(
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message
      );
    }
  };

  export function deleteBrand(id,token) {
    return (dispatch) => {
      return axios.delete(`${brandsEndpoint}delete/${id}`, {
        headers: {
          "x-access-token": token,
        }
      }).then((json) => {
        dispatch({ type: "DELETE_BRAND", payload: id });
        localStorage.setItem("brandDeleted",true)
      }).catch(err => {
        localStorage.setItem("brandDeleted",err.response.data.message)
        console.log(
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message
        )        
      });
    };
  }

  export const updateBrand = (brand,toke) => async (dispatch) => {
    /* console.log(cate) */
    try {

        const { data } = await axios.put(`${brandsEndpoint}update`, {brand});
        dispatch({ type: "UPDATE_BRAND", payload: data });
        localStorage.setItem("brandUpdated",true)
      }
      catch(err) {
        localStorage.setItem("brandUpdated",err.response.data.message)
        console.log(
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message
        )        
    };
  }
