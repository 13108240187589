import React from 'react'
import "../components/payments.css"
import correoafi from "../images/correoafiliado.webp"

const PaySeller = () => {

  async function copyToClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
      /* ✅ Copied successfully */
    } catch (e) {
      /* ❌ Failed to copy (insufficient permissions) */
    }
  }

  const writehora = () => {
    return (<>
      <div className='contenthora'>
        <h5>🕘 Horario de atención Paneles</h5>
        <div className='contentatencion'>
          <b>👇Atención UNICAMENTE dentro del horario👇</b>
        </div>
        <div>Lunes a viernes<br/>
          <b>9:00</b> a <b>13:00</b>carga de fichas y consultas<br />
          <b>16:00</b> a <b>20:00</b>consultas solamente<br />
          <b>Sabados, domingos y feriados: CERRADO</b></div>
      </div>
    </>)
  }

  const writeatencion = () => {
    return (<>
      <div className='contentatencion'>
        <b>☝️Atención UNICAMENTE dentro del horario☝️</b>
      </div>
    </>)
  }

  return (<>
    {writehora()}
    {writeatencion()}
    <h1>Creditos Panels</h1>
    <div className='contentprecio'><span className='divprecio'>Elija plan y solicite por cantidad</span>junto con el mail de su panel:</div>
    <div><img src={correoafi} alt="Paneles" /></div>
    <div style={{backgroundColor: 'lightgray'}}>Medios de pago</div>
    <div className='contentalias'>
      <div>💸 <span className='cuentas'>Lemon</span>: neura.norma.lemon<button className='boton' onClick={() => copyToClipboard("neura.norma.lemon")}>Copiar</button></div>
      <div>💸 <span className='cuentas'>Prex</span>: 27952878.prex<button className='boton' onClick={() => copyToClipboard("27952878.prex")}>Copiar</button></div>
      <div>💸 <span className='cuentas'>Uala</span>: enegraso.uala<button className='boton' onClick={() => copyToClipboard("enegraso.uala")}>Copiar</button></div>
      <div>💸 <span className='cuentas'>Belo</span>: fedeveloperbelo<button className='boton' onClick={() => copyToClipboard("fedebeloperbelo")}>Copiar</button></div>
      <div>💸 <span className='cuentas'>Personal</span>: fedeveloperppay<button className='boton' onClick={() => copyToClipboard("fedeveloperppay")}>Copiar</button></div>
      <div>💸 <span className='cuentas'>Claro</span>: fedevelopercpay<button className='boton' onClick={() => copyToClipboard("fedevolopercpay")}>Copiar</button></div>
      <div>💸 <span className='cuentas'>YPF</span>: fedevelopypf<button className='boton' onClick={() => copyToClipboard("fedevelopypf")}>Copiar</button></div>
      <div>💸 <span className='cuentas'>MercadoPago</span>: fedeveloper<button className='boton' onClick={() => copyToClipboard("fedeveloper")}>Copiar</button></div>
      <div>🏦 <span className='cuentas'>Naranja</span>: fedevelopernx<button className='boton' onClick={() => copyToClipboard("fedevelopernx")}>Copiar</button></div>
      <div>🏦 <span className='cuentas'>Provincia</span>: fedeveloperdni<button className='boton' onClick={() => copyToClipboard("fedeveloperdni")}>Copiar</button></div>
    </div>
    <div className='contentprecio'>Puede copiar el alias de su preferencia y luego pegarlo en su app de pago<br /><b>Debe enviar el comprobante una vez realizado el pago</b></div>
    <br />
    {writehora()}
    {writeatencion()}
  </>)
}

export default PaySeller