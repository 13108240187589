import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { getAllUsers } from "../../store/actions/users";
import { Link } from "react-router-dom";
import { MdAddCircle, MdEdit, MdDelete, MdArrowBack } from "react-icons/md";
import "./users.css";
import Spinner from "../spinner";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Avatar } from "@mui/material";

const UsersAdmin = (props) => {

    const userAdmin = useSelector((state) => state.User.userDetail);

    useEffect(
        () => {
         /*  if (props.allCategories.length === 0) */
          props.getAllUsers(userAdmin.token);
          console.log("Entro a buscar usuarios")
        }, [props.allUsersAdmin.length]
      );


    
/*     useEffect(async () => {
            
            console.log("entré con el token",userAdmin.token)
            allUsersAdmin = await dispatch(getAllUsers(userAdmin.token));
        }, [allUsersAdmin.length]
    );
 */

    if (!localStorage.getItem("userInfo"))
        return (
            <Link to="/loginadmin">
                <h5>Debe estar logueado</h5>
            </Link>
        );

    if (!props.allUsersAdmin)
        return (
            <>
                <Spinner />
            </>
        );

    return (
        <>
            <div className="listproducts">
                <div className="addbackhead">
                    <Link to="/admin/addbrand">
                        <button className="btn btn-success">
                            {" "}
                            <MdAddCircle />{" "}
                        </button>
                    </Link>
                    Listado de marcas
                    <Link to="/loginadmin">
                        <button className="btn btn-dark">
                            <MdArrowBack />
                        </button>
                    </Link>
                </div>
                {props.allUsersAdmin.map((user) => {
                    return (
                        <Grid item xs={12} /* md={12}  */ p={0.3} key={user.id}>
                            <Paper elevation={3} rounded="true" /* className="paper-prod" */>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-around"
                                    alignItems="center"
                                >
                                    <Grid item xs={3} alignItems="center">
                                        <Avatar src={user.image} alt={user.username} style={{ width: 56, height: 56 }}
                                        />
                                    </Grid>

                                    <Grid item xs={6} >{user.username}</Grid>
                                    <Grid item xs={2} >
                                        <div className="addback">
                                            <Link to="/admin/modbrand">
                                                <button
                                                    className="btn btn-success"
                                                    onClick={() => {
                                                        props.getBrandAdmin(user.id, userAdmin.token);
                                                    }}
                                                >
                                                    <MdEdit />{" "}
                                                </button>
                                            </Link>
                                            <Link to="/admin/delbrand">
                                                <button
                                                    className="btn btn-danger"
                                                    onClick={() => props.getBrandAdmin(user.id, userAdmin.token)}
                                                >
                                                    <MdDelete />{" "}
                                                </button>
                                            </Link>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    );
                })}
                <div className="addbackhead">
                    <Link to="/admin/addbrand">
                        <button className="btn btn-success">
                            {" "}
                            <MdAddCircle />{" "}
                        </button>
                    </Link>
                    Listado de marcas
                    <Link to="/loginadmin">
                        <button className="btn btn-dark">
                            <MdArrowBack />
                        </button>
                    </Link>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
  return {
    allUsersAdmin: state.User.allUsers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllUsers: (token) => dispatch(getAllUsers(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersAdmin);
