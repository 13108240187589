import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import NavBar from "./components/navBar";
import Home from "./views/home";
import Category from "./views/category";
import ProductsAdmin from "./components/productsAdmin/productsAdmin";
import CategoriesAdmin from "./components/CategoriesAdmin/CategoriesAdmin";
import ProductForm from "./components/productsAdmin/ProductForm";
import CategoryForm from "./components/CategoriesAdmin/CategoryForm";
import ProductFormMod from "./components/productsAdmin/ProductFormMod";
import OrderAdmin from "./components/OrdersAdmin/OrderAdmin";
import LoginAdmin from "./components/LogInAdmin/Login";
import DeleteProduct from "./components/productsAdmin/DeleteProduct";
import ImageProduct from "./components/productsAdmin/ImageProduct";
import DeleteCategory from "./components/CategoriesAdmin/DeleteCategory";
import UserForm from "./components/UserAdmin/UserForm";
import CategoryFormMod from "./components/CategoriesAdmin/CategoryFormMod";
import Paso1 from "./components/checkout/paso1";
import Paso2 from "./components/checkout/paso2";
import OrderAdminProcess from "./components/OrdersAdmin/OrderAdminProcess";
import OrderAdminFinish from "./components/OrdersAdmin/OrderAdminFinish";
import Configs from "./components/Configs/Configs";
import Efectivo from './components/checkout/efectivo';
import Success from './components/checkout/success'
import Register from "./components/UserAdmin/Register";
import Shop from "./views/shop";
import AbouUs from "./components/AboutUs";
import Contact from "./components/contact";
import Detail from "./components/productDetail";
import Servicios from "./views/servicios";
import Message from "./components/whatsNoContact";
import GridCart from "./views/carrito/gridCart";
import BrandsAdmin from "./components/BrandsAdmin/BrandsAdmin";
import BrandForm from "./components/BrandsAdmin/BrandForm";
import BrandFormMod from "./components/BrandsAdmin/BrandFormMod";
import DeleteBrand from "./components/BrandsAdmin/DeleteBrand";
import UsersAdmin from "./components/UserAdmin/usersAdmin";
import Wavisar from "./components/Wavisar"
import PayPerView from "./views/ppv";
import VerTorneo from "./components/VerTorneo";
import ListIPs from "./components/Ppv/list";
import Payments from "./components/Payments";
import PaySeller from "./components/PaySeller";
import InstallApp from "./components/InstalApp";
import Methods from "./components/Methods";
import PaySellerCF from "./components/PaySellerCF";

const excludedRoutes = ['/installapp', '/paymethods', '/payseller', '/paycf', '/mediospago', '/paysellercf' ];


const App = () => {
  const location = useLocation()
  console.log(location,excludedRoutes)
  return (
    <div className="App">
      {excludedRoutes.includes(location.pathname) ? null : <NavBar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/category" element={<Category />} />
        <Route path="/paso1" element={<Paso1 />} />
        <Route path="/paso2" element={<Paso2 />} />
        <Route path="/checkout" element={<Efectivo />} />
        <Route path="/success" element={<Success />} />
        <Route path="/admin/products" element={<ProductsAdmin />} />
        <Route path="/admin/categories" element={<CategoriesAdmin />} />
        <Route path="/admin/brands" element={<BrandsAdmin />} />
        <Route path="/admin/addimageprod" element={<ImageProduct />} />
        <Route path="/admin/addproduct" element={<ProductForm />} />
        <Route path="/admin/modproduct" element={<ProductFormMod />} />
        <Route path="/admin/delproduct" element={<DeleteProduct />} />
        <Route path="/admin/addcategory" element={<CategoryForm />} />
        <Route path="/admin/modcategory" element={<CategoryFormMod />} />
        <Route path="/admin/delcategory" element={<DeleteCategory />} />
        <Route path="/admin/addbrand" element={<BrandForm />} />
        <Route path="/admin/modbrand" element={<BrandFormMod />} />
        <Route path="/admin/delbrand" element={<DeleteBrand />} />
        <Route path="/admin/order/:id" element={<OrderAdmin />} />
        <Route path="/admin/orderprocess/:id" element={<OrderAdminProcess />} />
        <Route path="/admin/orderdelivered/:id" element={<OrderAdminFinish />} />
        <Route path="/admin/users" element={<UsersAdmin />} />
        <Route path="/loginadmin" element={<LoginAdmin />} />
        <Route path="/admin/user" element={<UserForm />} />
        <Route path="/admin/configs" element={<Configs />} />
        <Route path='/register' element={<Register />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/servicios" element={<Servicios />} />
        <Route path="/contacto" element={<Contact />} />
        <Route path="/detail/:id" element={<Detail />} />
        <Route path="/message" element={<Message />} />
        <Route path="/carrito" element={<GridCart />} />
        <Route path="/avisamail" element={<Wavisar />} />
        <Route path="/avisamail/:dia/:clave" element={<Wavisar />} />
        <Route path="/payper" element={<PayPerView />} />
        <Route path="/payper/ips" element={<ListIPs />} />
        <Route path="/vertorneo" element={<VerTorneo />} />
        <Route path="/paymethods" element={<Payments />} />
        <Route path="/payseller" element={<PaySeller />} />
        <Route path="/paysellercf" element={<PaySellerCF />} />
        <Route path="/installapp" element={<InstallApp />} />
        <Route path="/mediospago" element={<Methods />} />
      </Routes>
      {excludedRoutes.includes(location.pathname) ? null : <AbouUs />}
    </div>
  );
}

export default App;
