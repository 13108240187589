import React, { useState } from "react";
import axios from "axios";
import { Image, Transformation } from "cloudinary-react";
import "./brands.css";
import { urlPost } from "../../store/actions/products";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {REACT_APP_CLOUPRESET, REACT_APP_CLOUUSER } from "../../store/consts/consts";


const BrandImage = () => {
  const [imgSelected, setImgSelected] = useState();
  const [photoData, setPhotoData] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const uploadImage = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(imgSelected);
    const formData = new FormData();
    formData.append("file", imgSelected);
    formData.append("upload_preset", REACT_APP_CLOUPRESET);
    await axios
      .post("https://api.cloudinary.com/v1_1/" + REACT_APP_CLOUUSER + "/image/upload", formData)
      .then((response) => {
        setPhotoData(response.data.url);
        console.log(response.data.url);
        setLoading(false);
        dispatch(urlPost(response.data.url));
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sinImagen = async (e) => {
    setLoading(false);
    await dispatch(urlPost(""));
  };

  return (
    <div className="imageupch" >
      <form action="/pedidos" method="post" encType="multipart/form-data">
        <div class="mb-3">
          <label class="form-label">
            <h6>Ingrese logo o imagen</h6>
          </label>
          {/* <input name="image" placeholder="Upload a photo" className="container" type="file" /> */}
          <input
            class="form-control btn btn-outline-success"
            type="file"
            name="foto"
            onChange={(event) => {
              setImgSelected(event.target.files[0]);
            }}
          />
        </div>
        <div class="mb-3"> {loading && <i> Cargando... </i>}</div>
        <div class="mb-3">
          Luego de elegir archivo, presione{" "}
          <button
            class="btn btn-outline-success"
            disabled={loading}
            type="submit"
            value="Create"
            onClick={uploadImage}
          >
            {" "}
            Cargar Imagen{" "}
          </button>
       </div>
        <div class="mb-3">
          <Image cloudName={REACT_APP_CLOUUSER} publicId={photoData}></Image>
        </div>
        <div class="mb-3">
          {/*           {" "}
          {photoData && (
            <Link to="/admin/addproduct">
              <button class="btn btn-success"> Continuar alta </button>
            </Link>
          )} */}
        </div>
      </form>
    </div>
  );
};

export default BrandImage;
