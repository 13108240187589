import img01 from "../../images/hardware.png"
import img02 from "../../images/hardware.png"
import "../home/styles.css"

const Servicios = () => {
    return <>
        <h1 className="h1">Servicios Informáticos</h1>
        <h3 className="h3">Desarrollo de sitios y aplicaciones web</h3>
        <img src={img01} alt="Servicios Informáticos Bragado" title="Sitios Web" style={ {width: "100%", maxWidth: "550px", height: "auto"}} />
        <h3 className="h3">Soporte técnico de computadoras</h3>
        <img src={img02} alt="Servicios Informáticos Bragado" title="Soporte técnico" style={ {width: "100%", maxWidth: "550px", height: "auto"}} />
    </>
}

export default Servicios