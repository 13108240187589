import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getProductId } from "../../store/actions/products";
import style from "./productDetail.module.css";


function Detail(props) {

  const dispatch = useDispatch()
  const navigation = useNavigate()
  const params = useParams();
  const product = useSelector((state) => state.Product.product)

  useEffect(async () => {
    await dispatch(getProductId(params.id));
    console.log("useefect", params.id)

  }, [params.id]);

  console.log("producto", product)

  function goBack() {
    // props.cleardetail();
    navigation(-1);
  }

  if (product.length === 0) {
    return (
      <img
        className={style.imgloader}
        src="https://i.gifer.com/ZZ5H.gif"
        alt="loader"
      />
    );
  }

  return (
    <>
      <button
        className={style.centerbut}
        onClick={() => {
          goBack();
        }}
      >
        Volver 👈
      </button>
      <div className={style.containerprod}>
        <div className={style.card_container}>
          <div className={style.header}>
            <div className={style.imagen_container}>
              <img
                src={product.image}
                className={style.imagen}
                /*  width="400"
                  height="300" */
                alt="img breed"
              />
            </div>
            <h2>{product.name}</h2>
            <h4 className={style.textwhite}>{product.description}</h4>
          </div>
        </div>
        <div className={style.card_container}>
          <div className={style.description}>
            <p className={style.wc}>
              <strong>Detalles</strong>
            </p>
            <p className={style.textwhite}>
              Precio: $ {product.price}
            </p>
            <p className={style.textwhite} >
              Marca: {product.brand.brand}
            </p>
            <p className={style.textwhite} >
              Descripción de marca: {product.brand.description}
            </p>
            {
              product.categories && product.categories.map((categ) => {
               return <>
                <p className={style.textwhite} >
                  Categoría: {categ.category}
                </p>
                  <p className={style.textwhite} >
                    Descripción de categoría: {categ.description}
                  </p>
              </>
              })
            }
            {product.linkinfo ? <p className={style.textwhite} >
              Más info: <a href={product.linkinfo} target="_blank"> click aquí</a>
            </p> : <p></p>


            }
          </div>
        </div>
      </div>
      <button
        className={style.centerbut}
        onClick={() => {
          goBack();
        }}
      >
        Volver 👈
      </button>
    </>
  );
}

export default Detail
