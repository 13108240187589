import { useDispatch, useSelector } from "react-redux"
import { BiLogOut } from "react-icons/bi";
import swal from "sweetalert2";
import { logOut } from "../../store/actions/users";
import { Link, useNavigate } from "react-router-dom";

import { Grid, Paper } from "@mui/material";


const Userdash = (props) => {
    const navigation = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(state => state.User.userDetail)

    const handleClick = (e) => {
        swal
          .fire({
            title: "Realmente desea salir?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: `Sí`,
            icon: "question",
            // denyButtonText: `Cancelar`,
          })
          .then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              dispatch(logOut(localStorage.getItem("userInfo", JSON.stringify("id"))));
              navigation("/")
            }
          });
      };
    
    return <>
        <h4>
            Hola {user.name}{" "}
            <button className="btn btn-light" onClick={handleClick}>
                <BiLogOut
                    style={{
                        border: "2px dash forestgreen",
                        width: "32px",
                        height: "32px",
                    }}
                />
            </button>
        </h4>
        <Grid p={1}>
          <Paper elevation={2} rounded="true" className="menuitems">
{/*             <Link to="/admin/categories">Categorías</Link>
            <Link to="/admin/brands">Marcas</Link>
            <Link to="/admin/products">Productos</Link>
            <Link to="/admin/users">Usuarios</Link>
            <Link to="/admin/configs">Configs</Link> */}
            <Link to="/admin/user">Perfil</Link>
          </Paper>
        </Grid>
    </>
}

export default Userdash