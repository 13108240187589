import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from '../../images/logo192.png'
import "./nav.css";
import { MdShoppingCart } from "react-icons/md";
import { useSelector } from "react-redux";
import shoppingBag from "../utils/img/shopping-bag.png";
import Carrito from "../../views/carrito";
import { orderline, total } from "../utils";
import { /* animateScroll as scroll, */ Link as LinkS } from "react-scroll";
import SearchBar from "../searchBar";
import Title from "./title";

/* import { totalcant } from "../../components/utils"; */

export default function NavBar() {
  const orden = useSelector((state) => state.Carrito.guestCart);
  const [itemsCart, setItemsCart] = useState(0);
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const location = useLocation();
  const guestOrderlines = orderline(orden);

  useEffect(() => {
    setItemsCart(guestOrderlines.length);
    // Preparo items
  }, [guestOrderlines]);

  return (
    <>
      <nav className="navigation">
        {<Link to="/" onClick={() => setIsNavExpanded(false)}>
          <img src={logo} alt="SIB 2000" className="navbar-logo"></img>
        </Link>}
        {location.pathname !== "/shop" ? <Title /> : ""}
        {location.pathname === "/shop" ? (
          <div
            style={{ width: "45%", marginLeft: "auto", marginRight: "auto", alignItems: "center" }}
          >
            <SearchBar />
          </div>
        ) : (
          ""
        )}
        <button
          title=""
          className="hamburger"
          onClick={() => {
            setIsNavExpanded(!isNavExpanded)
          }}
        >
          {/* icon from heroicons.com */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="white"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <div
          className={
            isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
          }
        >
          <ul>
            <li>
              <Link to="/shop" onClick={() => setIsNavExpanded(false)}>Productos</Link>
            </li>
            <li>
              <Link to="/servicios" onClick={() => setIsNavExpanded(false)}>Servicios</Link>
            </li>
            <li>
              <Link to="/desarrollos" onClick={() => setIsNavExpanded(false)}>Desarrollos</Link>
            </li>
            <li>
              <Link to="/contacto" onClick={() => setIsNavExpanded(false)}>Contacto</Link>
            </li>

            {console.log("Usuario", localStorage.getItem("userInfo"))}
            {

              !localStorage.getItem("userInfo", JSON.stringify("token")) ?
                <>
                  <li>
                    <Link to="/loginadmin" onClick={() => setIsNavExpanded(false)}>Login</Link></li>
                   </>
                : <>
                  <li>
                    <Link to="/loginadmin" onClick={() => setIsNavExpanded(false)}>
                      Admin
                    </Link>
                  </li></>
            }
            <li>
              <Link to="/payper" onClick={() => setIsNavExpanded(false)}>Stream PPV</Link>
            </li>
          </ul>
        </div>

        {/* carrito de compras */}
        <div
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
        >
          <MdShoppingCart className="iconcart" />
          <span className="badge bg-danger">
            {itemsCart > 0 ? itemsCart : ""}
          </span>
        </div>

        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
              {itemsCart > 0 ? itemsCart : ""} Items
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>

          <div className="offcanvas-body">
            <>
              {itemsCart === 0 ? (
                <div>
                  <img
                    className="shopBagImg"
                    src={shoppingBag}
                    alt="Continue Shopping"
                  />
                  <h5 style={{ color: "gray" }}>
                    Tu bolsa de compras esta vacia
                  </h5>
                </div>
              ) : (
                <>
                  <Carrito />
                  <h5 style={{ margin: "10px" }}>
                    $ {total(guestOrderlines).toFixed(2).replace(".", ",")}
                  </h5>
                  <button className="btn btn-outline-success" data-bs-dismiss="offcanvas">
                    <Link to="/carrito"
/*                       smooth={true}
                      duration={1500} */
                       
                    >
                      VER CARRITO
                    </Link>
                  </button>
                </>
              )}
            </>
          </div>
        </div>

      </nav>

    </>
  );
}
