import { useEffect, useState } from "react";
import { REACT_APP_API } from "../../store/consts/consts";
import axios from 'axios'
import Spinner from "../spinner";
import "../../views/ppv/styles.css"

const ListIPs = () => {

    const [listIPs, setListIPs] = useState([])
    const [isloading, setIsloading] = useState(true)
    const [isInactive, setIsInactive] = useState(0)
    let pagos = 0
    let ganado = 0
    let pagados = []
    let impagos = []


    useEffect(() => {
            setIsloading(true)
            ObtenIPs()
    }, [isInactive]);

    const ObtenIPs = async () => {
        await axios.get(`${REACT_APP_API}payper/`)
            .then((res) => {
                setListIPs(res.data)
                setIsloading(false)

            })
            .catch((error) => {
                setIsloading(false)

            })
    }


    // activación de IPs comprados y que sistema no activó automáticamente
    async function handleClick (i) {
            await axios.get(`${REACT_APP_API}payper/acti/${i.ip}`).
            then((res) => {
                pagos += 1
                alert(i.ip + " ha sido activado!")
                setIsInactive(listIPs.length - pagos) 
            }).catch((error) => {
                alert(i.ip + " NO se pudo activar " + error)
            })
    }

    return (<div className="containerlist">
        {!isloading ?
            listIPs.length > 0 ?
                listIPs.map((item, index) => {
                    if (item.active) {
                        pagos += 1
                        ganado += item.monto
                        pagados.push(item)
                    } else {
                        impagos.push(item)
                    }
                    console.log(pagados)
                }) : <><h1>No se encontraron IPs cargados </h1></>
            : <Spinner />}
        <div className="boxavisolist">
            <div><h5 className="card-title">Pagados</h5></div>
            {pagados.map(i => {
                return (
                    <div key={i.id}>
                        IP: {i.ip} - Importe {i.monto}
                    </div>
                )

            })}
        </div><div className="boxavisolist">
            <div><h5 className="card-title">Impagos</h5></div>
            {impagos.map(i => {
                return (
                    <div key={i.id}>
                        IP: {i.ip} - Importe {i.monto}
                        <button className="btn btn-primary" onClick={() => {handleClick(i)}}>Activar</button>

                    </div>
                )

            })}
        </div>
        <div style={{ width: "100%" }}>
            {
                listIPs.length > 0 ? <>
                    <hr />
                    <div>
                        IPs Interesados {listIPs.length}
                    </div>
                    <div>
                        IPs Comprados {pagos} $ {(Math.round(ganado)).toFixed(2).replace('.', ',')} <br />
                        - comision MP $ {(Math.round(ganado) * 8 / 100).toFixed(2).replace('.', ',')} <br />
                        = $ {(Math.round(ganado) - (Math.round(ganado) * 8 / 100)).toFixed(2).replace('.', ',')}
                    </div>
                    <div>
                        IPs impagos {listIPs.length - pagos}
                    </div> </> : <></>}
        </div>
    </div>)

}

export default ListIPs