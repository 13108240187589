import axios from 'axios'
import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { REACT_APP_API_HOOK, REACT_APP_API_WA } from "../../store/consts/consts";
import Spinner from '../spinner';

const Wavisar = (props) => {


  const [errors, setErrors] = React.useState({});
  const [posts, setPosts] = useState([]);
  const [dianum, setDianum] = useState(0);
  const [laclave, setLaclave] = useState("");
  const [isloading, setisloading] = useState(true)
  const params = useParams();
  const navegar = useNavigate();
  const avisar = {
    diaavisa: params.dia
  }
  const clave = {
    clave: params.clave
  }

  async function copyToClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
      /* ✅ Copied successfully */
    } catch (e) {
      /* ❌ Failed to copy (insufficient permissions) */
    }
  }
  // console.log("Clave", clave)

  useEffect(() => {
    setisloading(true)
    async function fetchData() {

      try {
        const res = await axios.post(`${REACT_APP_API_HOOK}webhooks/google`, avisar);
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      } finally {
        setisloading(false)
      }
    }
    fetchData();
  }, [params.dia]);

  // console.log(avisar)


  const handleSubmit = (event) => {
    event.preventDefault();
    navegar('/avisamail/' + dianum + '/' + laclave)
    // navegar("/avisatv/30")
  }

  function handleChange(e) {
    if (e.target.name === "dianum")
      setDianum(e.target.value);
    if (e.target.name === "laclave")
      setLaclave(e.target.value)
  }


  if (params.dia === undefined) {
    return <>
      <h1>Ingrese dia de vencimiento</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <input
            className={errors.diaNum}
            placeholder="Dia del mes"
            type="number"
            name="dianum"
            required="required"
            value={dianum}
            onChange={handleChange}
          />
          {errors.diaNum && (
            <p>{errors.dianum}</p>
          )}
          <input
            className={errors.clave}
            placeholder="Password"
            type="password"
            name="laclave"
            required="required"
            value={laclave}
            onChange={handleChange}
          />
        </div>
        <button type="submit">Avisar</button>
      </form>
    </>
  } else if (clave.clave === "swordfish") {
    return <div>
      <h1>Emails a vencer</h1>
      { !isloading ? 
        posts.map((cliente, index) =>
        <div key={index} style={{ backgroundColor: "lightgray", color: "black" }}>
          <div>{index + 1} - Vence: {cliente.vence} - <span style={{ fontSize: "1.2em", color: "green" }}>{cliente.rol}</span> - {cliente.cuenta} - {cliente.celu} - <span style={{ fontSize: "1.5em", backgroundColor: "black", color: "white" }}>{cliente.pago}</span></div>
          <div><button style={{ backgroundColor: "green" }} onClick={
            () => window.open(`${REACT_APP_API_WA}send?phone=` + `${cliente.celu}`)
          } >Avisar x Whatsapp</button><button onClick={() => copyToClipboard(cliente.celu)}>Copy</button></div>
          <hr />
        </div>) : <Spinner />
      }
    </div>
  } else {
    return <>
      <h1>Clave errónea</h1>
    </>
  }
}

export default Wavisar;