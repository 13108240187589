import React, { useEffect, useState } from "react";
import "./Form.css";
import "./Register.css";
import Home from "../../views/home";
import { userAdd } from '../../store/actions/users'
import { useDispatch } from "react-redux";
import swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { REACT_APP_API, REACT_APP_GEO } from "../../store/consts/consts"

function Register() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [input, setInput] = React.useState({
    username: "",
    password: "",
    rpassword: "",
    name: "",
    email: "",
    cellphone: "",
    address: "",
    city: "",
    zip: "",
    province: "",
    country: "",
    isAdmin: false
  });
  const [errors, setErrors] = React.useState({});

  const [users, setUsers] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingReg, setIsLoadingReg] = useState(false);
  const [provincias, setProvincias] = useState()

  async function getUsuarios() {
    const url = REACT_APP_API + "users/usernames"
    await fetch(url)
      .then((response) => response.json())
      .then((json) => {
        setUsers(json);
        setIsLoading(true);
        console.log("USUARIOS", users)
      })
      .catch((err) => setErrors(err));
  }

/*   const getProvincias = async () => {
    const uriprov = REACT_APP_GEO + "provincias"
    console.log(uriprov)
    await fetch(uriprov)
      .then((response) => response.json())
      .then((json) => {
        setProvincias(json);
        // setIsLoading(true);
        console.log("Provincias", provincias)
      })
      .catch((err) => setErrors(err));
  } */

  useEffect(() => {
    getUsuarios();
/*     getProvincias() */
  }, []);


  function validate(input) {
    var emailPattern = /\S+@\S+\.\S+/; // Expresion Regular para validar Emails.
    var encontrado;
    let errors = {};
    if (!input.username) {
      errors.username = "Username is required";
    } else {
      encontrado = users.find((user) => user === input.username);
      if (encontrado) {
        errors.username = "Username already exist";
      }
    }
    if (!input.password) {
      errors.password = "Password is required";
    } else if (input.password.length < 6) {
      errors.password = "Password must have 6 characters";
    } else if (!/(?=.*[0-9])/.test(input.password)) {
      errors.password = "Password is invalid";
    }
    if (!input.rpassword) {
      errors.rpassword = "Control Password is required";
    } else if (input.rpassword.length < 6) {
      errors.rpassword = "Control Password must have 6 characters";
    } else if (!/(?=.*[0-9])/.test(input.rpassword)) {
      //(!/(?=.*[0-9])/.test(input.password))
      errors.rpassword = "Control Password is invalid";
    } else if (input.password !== input.rpassword) {
      errors.rpassword = "Control and Passwords not equals";
    }
    if (input.email.length > 0 && !emailPattern.test(input.email)) {
      errors.email = "Direccion de correo invalido";
    }
    if (input.cellphone.length !== 0 && input.cellphone.length !== 10) {
      errors.cellphone = "Celular invalido";
    }
    if (input.name.length !== 0 && input.name.length !== 2) {
      errors.name = "Ingrese un nombre";
    }
    return errors;
  }

  const handleInputChange = function (e) {
    // validate(e.target.name,e.target.value)
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    setErrors(
      validate({
        ...input,
        [e.target.name]: e.target.value,
      })
    );
  };

  async function handleSubmit(e) {
    // funcion que va a despachar la accion getuser (deberia llamarse login)
    e.preventDefault();
    await dispatch(userAdd(input))
    if (localStorage.getItem("userAdded") === "true") {
      swal
        .fire({
          title: "Usuario agregado exitosamente",
          confirmButtonText: `Aceptar`,
          icon: "success",
          // denyButtonText: `Cancelar`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            navigate('/');
          }
        });
    } else {
      // console.log("Que corno traigo",localStorage.getItem("productUpdated"))
      swal.fire({
        title: localStorage.getItem("userAdded"),
        confirmButtonText: `Ok`,
        icon: "error",
        // denyButtonText: `Cancelar`,
      });
    }
  }

  if (isLoadingReg) {
    return (
      <Home />
    )
  }

  return (
    <div className="regcontainer">
      <div className="boxreg">
        <div className="titreg">Registrate para comprar</div>
        <div className="cuerporeg">
          Ingresa un nombre de usuario y una contraseña rara registrarte
        </div>
        <div>
          <form onSubmit={handleSubmit}>
            <div>
              <label>Usuario: (no es necesario mail verdadero)</label>
              <input
                className={errors.username && "danger"}
                type="text"
                name="username"
                placeholder="usuario"
                onChange={handleInputChange}
                value={input.username}
                required
              ></input>
              {errors.username && <p className="danger">{errors.username}</p>}
            </div>
            <div>
              <label>
                Contraseña: (al menos 6 caracteres)
              </label>
              <input
                className={errors.password && "danger"}
                type="password"
                name="password"
                onChange={handleInputChange}
                value={input.password}
              ></input>
              {errors.password && <p className="danger">{errors.password}</p>}
            </div>
            <div>
              <label>Control Contraseña: </label>
              <input
                className={errors.rpassword && "danger"}
                type="password"
                name="rpassword"
                onChange={handleInputChange}
                value={input.rpassword}
              ></input>
              {errors.rpassword && <p className="danger">{errors.rpassword}</p>}
            </div>
            <div>
              <label>Email: </label>
              <input
                className={errors.email && "danger"}
                type="email"
                name="email"
                onChange={handleInputChange}
                value={input.email}
              ></input>
              {errors.email && <p className="danger">{errors.email}</p>}
            </div>
            <div>
              <label>Nombre y Apellido</label>
              <input
                className={errors.name && "danger"}
                type="text"
                name="name"
                placeholder="ingresar nombre"
                onChange={handleInputChange}
                value={input.name}
              ></input>
              {errors.name && <p className="danger">{errors.name}</p>}
            </div>

            <div>
              <label>Teléfono: </label>
              <input
                className={errors.cellphone && "danger"}
                type="text"
                name="cellphone"
                placeholder="sin 0 y sin 15"
                onChange={handleInputChange}
                value={input.cellphone}
              ></input>
              {errors.cellphone && <p className="danger">{errors.cellphone}</p>}
            </div>
            <div>
              <label>Dirección: </label>
              <input
                /* className={errors.cellphone && "danger"} */
                type="text"
                name="address"
                placeholder="dirección, si desea recbir en domicilio"
                onChange={handleInputChange}
                value={input.address}
              ></input>
              {/* {errors.cellphone && <p className="danger">{errors.cellphone}</p>} */}
            </div>
            <div>
              <label>Localidad: </label>
              <input
                /* className={errors.cellphone && "danger"} */
                type="text"
                name="city"
                placeholder="localidad, si desea recbir en domicilio"
                onChange={handleInputChange}
                value={input.city}
              ></input>
              {/* {errors.cellphone && <p className="danger">{errors.cellphone}</p>} */}
            </div>
            <div>
              <label>Código Postal: </label>
              <input
                /* className={errors.cellphone && "danger"} */
                type="text"
                name="zip"
                placeholder="código postal, si desea recbir en domicilio"
                onChange={handleInputChange}
                value={input.zip}
              ></input>
              {/* {errors.cellphone && <p className="danger">{errors.cellphone}</p>} */}
            </div>
            <div>
              <label>Provincia: </label>
              <input
                /* className={errors.cellphone && "danger"} */
                type="text"
                name="province"
                placeholder="provincia, si desea recbir en domicilio"
                onChange={handleInputChange}
                value={input.province}
              ></input>
              {/* {errors.cellphone && <p className="danger">{errors.cellphone}</p>} */}
            </div>
            <div>
              <label>País: </label>
              <input
                /* className={errors.cellphone && "danger"} */
                type="text"
                name="country"
                placeholder="contry, si desea recbir en domicilio"
                onChange={handleInputChange}
                value={input.country}
              ></input>
              {/* {errors.cellphone && <p className="danger">{errors.cellphone}</p>} */}
            </div>
            <div>
              <button>Registrate</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Register;
