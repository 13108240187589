// Kindacode.com
import React, { useRef, useState } from 'react';
import { REACT_APP_CLAVESITIO, REACT_APP_CLAVESECRETA } from '../../store/consts/consts';
import Reaptcha from "reaptcha"
import Swal from 'sweetalert2';

import './form.css';
import Helmet from 'react-helmet';

const Message = () => {

  const [term, setTerm] = useState('');
  const [text, setText] = useState('');
  const [errmessage, setErrMessage] = useState('por favor complete el captcha')

  const [captchaToken, setCaptchaToken] = useState(null);
  const captchaRef = useRef(null);

  const verify = () => {
    captchaRef.current.getResponse().then(res => {
      setCaptchaToken(res)
    })

  }

  const handleSubmit = (event) => {
    // Preventing the page from reloading
    event.preventDefault();

    // Do something 
    // alert("TOKEN Captcha", captchaToken, "captcharef", captchaRef);

    //
    captchaToken === null ?       Swal.fire({
      title: "Atención " + errmessage,
      showDenyButton: false,
      showCancelButton: false,
      confirmButtonText: `Error`,
      icon: "error",
    }) : window.open("https://api.whatsapp.com/send?phone=" + term + "&text=" + text, "_blank"); setCaptchaToken(null); setErrMessage('Debe refrescar esta página')

  }

  return (<>
    <Helmet>
      <title>Servicios Informáticos Bragado - Send message whatsapp without contact</title>
      <meta name="description" content="Enví mensaje de whatsApp sin tener el numero en sus contactos" />
      <meta name="keywords" content="bragado, servicios, informaticos, whtasapp, api, send, message, without, contact" />
      <meta property="og:url" content="sib-2000.com.ar/message" />
      <meta property="og:site_name" content="Servicios Informáticos Bragado" />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://sib-2000.com.ar/logos/logosib.png" />
      <meta property="og:description" content="Sitio Web Oficial de productos de computación y tecnología." />
      <link rel="canonical" href="sib-2000.com.ar" />
    </Helmet>
    <div className="containerform">
      <form onSubmit={handleSubmit}>
        <label>Please input cellphone number / Ingrese numero de whatsapp</label>
        <input
          value={term}
          onChange={(e) => setTerm(e.target.value)}
          type="number"
          placeholder="542342111111"
          className="input"
          required
        />
        <label>Please input text to send (optional) / Ingrese texto a enviar (opcional)</label>
        <input
          value={text}
          onChange={(e) => setText(e.target.value)}
          type="text"
          placeholder="Enter a text to send"
          className="input"
        />
        <Reaptcha
          sitekey={REACT_APP_CLAVESITIO}
          ref={captchaRef}
          onVerify={verify}
        />
        <button type="submit" className="btn-success">Submit</button>
      </form>
    </div>
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6541245434978705"
        crossOrigin="anonymous"></script></>
  );
};

export default Message;