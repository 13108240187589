import "videojs-youtube";
import "video.js/dist/video-js.css";
import "./styles.css"

export default function VideoPlay() {

  return (
    <div>
      <div className="video-responsive">
        <iframe src="https://inliveserver.com:2000/VideoPlayer/8012" scrolling="no" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
      </div>

      <div style={{ paddingBottom: 15 }}>
        <button style={{ borderRadius: 5, backgroundColor: "orange", color: "white" }} onClick={() => window.location.href = '../payper'}>Refrescar Player</button>
      </div>

      <div style={{ paddingBottom: 15 }}>
        <button style={{ borderRadius: 5, backgroundColor: "green", color: "white" }} onClick={() => window.open("https://api.whatsapp.com/send?phone=542342513085", '_blank')}>Contacto 2342 513085</button>
      </div>


    </div>
  );
}