import React from 'react'
import "../components/payments.css"

const Methods = () => {

    async function copyToClipboard(text) {
        try {
            await navigator.clipboard.writeText(text);
            /* ✅ Copied successfully */
        } catch (e) {
            /* ❌ Failed to copy (insufficient permissions) */
        }
    }

    const writehora = () => {
        return (<>
            <div className='contenthora'>
                <h5>🕘 Horario de atención</h5>
                <div className='contentatencion'>
                    <b>👇Atención UNICAMENTE dentro del horario👇</b>
                </div>
                <div>Lunes a viernes<br />
                    9:00 a 13:00 - 16:00 a 20:00<br />
                    <b>Sabados, domingos y feriados: CERRADO</b></div>
            </div>
        </>)
    }

    const writeatencion = () => {
        return (<>
            <div className='contentatencion'>
                <b>☝️Atención UNICAMENTE dentro del horario☝️</b>
            </div>
        </>)
    }

    return (<>
        {writehora()}
        {writeatencion()}
        <h1>Métodos de pago correo S2K</h1>
        <div className='contentprecio'>Puede transferir el monto solicitado/deseado a cualquiera de los siguientes alias:</div>
        <div className='contentalias'>
            <div>💸 <span className='cuentas'>Lemon</span>: neura.norma.lemon<button className='boton' onClick={() => copyToClipboard("neura.norma.lemon")}>Copiar</button></div>
            <div>💸 <span className='cuentas'>Prex</span>: 27952878.prex<button className='boton' onClick={() => copyToClipboard("27952878.prex")}>Copiar</button></div>
            <div>💸 <span className='cuentas'>Uala</span>: enegraso.uala<button className='boton' onClick={() => copyToClipboard("enegraso.uala")}>Copiar</button></div>
            <div>💸 <span className='cuentas'>Belo</span>: fedeveloperbelo<button className='boton' onClick={() => copyToClipboard("fedebeloperbelo")}>Copiar</button></div>
            <div>💸 <span className='cuentas'>Personal</span>: fedeveloperppay<button className='boton' onClick={() => copyToClipboard("fedeveloperppay")}>Copiar</button></div>
            <div>💸 <span className='cuentas'>Claro</span>: fedevelopercpay<button className='boton' onClick={() => copyToClipboard("fedevolopercpay")}>Copiar</button></div>
            <div>💸 <span className='cuentas'>YPF</span>: fedevelopypf<button className='boton' onClick={() => copyToClipboard("fedevelopypf")}>Copiar</button></div>
            <div>💸 <span className='cuentas'>MercadoPago</span>: fedeveloper<button className='boton' onClick={() => copyToClipboard("fedeveloper")}>Copiar</button></div>
            <div>🏦 <span className='cuentas'>Naranja</span>: fedevelopernx<button className='boton' onClick={() => copyToClipboard("fedevelopernx")}>Copiar</button></div>
            <div>🏦 <span className='cuentas'>Provincia</span>: fedeveloperdni<button className='boton' onClick={() => copyToClipboard("fedeveloperdni")}>Copiar</button></div>
        </div>
        <div className='contentprecio'>Puede copiar el alias de su preferencia y luego pegarlo en su app de pago<br /><b>Debe enviar el comprobante una vez realizado el pago</b></div>
        <br />
        <div style={{ backgroundColor: "lightgray" }}>O en efectivo 💵 en <b>Rapipago</b> a cuenta <b>PREX: 10408748</b></div>
        <br />
        <br />
        {writehora()}
        {writeatencion()}
    </>)
}

export default Methods