import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { getAllBrands, getBrandAdmin } from "../../store/actions/brands";
import { Link } from "react-router-dom";
import { MdAddCircle, MdEdit, MdDelete, MdArrowBack } from "react-icons/md";
import "./brands.css";
import Spinner from "../spinner";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Avatar } from "@mui/material";

const BrandsAdmin = (props) => {

  const userAdmin = useSelector((state) => state.User.userDetail);
  
  useEffect(
    () => {
     /*  if (props.allCategories.length === 0) */
      props.getAllBrands(userAdmin.token);
    }, [props.allBrands.length]
  );

  if (!localStorage.getItem("userInfo"))
    return (
      <Link to="/loginadmin">
        <h5>Debe estar logueado</h5>
      </Link>
    );

  if (!props.allBrands)
    return (
      <>
        <Spinner />
      </>
    );

  return (
    <>
      <div className="listproducts">
        <div className="addbackhead">
          <Link to="/admin/addbrand">
            <button className="btn btn-success">
              {" "}
              <MdAddCircle />{" "}
            </button>
          </Link>
          Listado de marcas
          <Link to="/loginadmin">
            <button className="btn btn-dark">
              <MdArrowBack />
            </button>
          </Link>
        </div>
        {props.allBrands.map((brand) => {
          return (
            <Grid item xs={12} /* md={12}  */p={0.3} key={brand.id}>
              <Paper elevation={3} rounded="true" /* className="paper-prod" */>
                <Grid
                  container
                  direction="row"
                   justifyContent="space-around"
                  alignItems="center"
                 >
                  <Grid item xs={3} alignItems="center">
                  <Avatar src={brand.image} alt={brand.brand} style={{ width: 56, height: 56 }}
                  />
                  </Grid>

                  <Grid item xs={6} >{brand.brand}</Grid>
                  <Grid item xs={2} >
                    <div className="addback">
                    <Link to="/admin/modbrand">
                      <button
                        className="btn btn-success"
                        onClick={() => {
                          props.getBrandAdmin(brand.id,userAdmin.token);
                        }}
                      >
                        <MdEdit />{" "}
                      </button>
                    </Link>
                    <Link to="/admin/delbrand">
                      <button
                        className="btn btn-danger"
                        onClick={() => props.getBrandAdmin(brand.id,userAdmin.token)}
                      >
                        <MdDelete />{" "}
                      </button>
                    </Link>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          );
        })}
        <div className="addbackhead">
          <Link to="/admin/addbrand">
            <button className="btn btn-success">
              {" "}
              <MdAddCircle />{" "}
            </button>
          </Link>
          Listado de marcas
          <Link to="/loginadmin">
            <button className="btn btn-dark">
              <MdArrowBack />
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allBrands: state.Brand.allBrands,
    brandAdminGet: state.Brand.brandAdminGet,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllBrands: (token) => dispatch(getAllBrands(token)),
    getBrandAdmin: (id,token) => dispatch(getBrandAdmin(id,token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandsAdmin);
