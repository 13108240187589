import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { prodAdd } from "../../store/actions/products";
import { getAllCategories } from "../../store/actions/categories";
import { getAllBrands } from "../../store/actions/brands";
import { FaUserCircle } from "react-icons/fa";
import "./products.css";
import swal from "sweetalert2";
import { Link } from "react-router-dom";

export function validateprod(input) {
  /* var emailPattern = /\S+@\S+\.\S+/; */ // Expresion Regular para validar Emails.

  let errors = {};
  if (!input.name) {
    errors.name = "Por favor, Ingrese nombre de producto";
  } /* else if (!emailPattern.test(input.username)) {
    errors.username = "Username is invalid";
  } */
  if (!input.price || input.price <= 0) {
    errors.price = "Por favor, ingresar precio para producto";
  }

  return errors;
}

let data = []

const ProductForm = () => {
  const dispatch = useDispatch();
  const categoriasAdmin = useSelector((state) => state.Category.allCategories);
  const brandsAdmin = useSelector((state) => state.Brand.allBrands)
  const userAdmin = useSelector((state) => state.User.userDetail);
  const [image, setImage] = React.useState([]);

  useEffect(() => {
    if (categoriasAdmin.length === 0) dispatch(getAllCategories(userAdmin.token));
    if (brandsAdmin.length === 0) dispatch(getAllBrands(userAdmin.token));
  }, [])


  const [input, setInput] = React.useState({
    name: "",
    description: "",
    exist: false,
    price: 0,
    isOfert: false,
 //   image: localStorage.getItem("urlImage").replaceAll('"', ""),
    stockunits: 0,
    units: "unidad",
    capacity: 0,
    capacityunit: "ml",
    minunit: 1,
    stepunit: 1,
    categories: [],
    brands: []
  });
  const [errors, setErrors] = React.useState({});

  const handleInputChange = function (e) {
    // validate(e.target.name,e.target.value)
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    setErrors(
      validateprod({
        ...input,
        [e.target.name]: e.target.value,
      })
    );
  };

  async function handleSubmit(e) {
    // funcion que debe solicitar usuario logueado
    e.preventDefault();
    console.log(input);
    const objProductNew = {
      ...input,
      image
    }
    localStorage.removeItem("urlImage");
    await dispatch(prodAdd(objProductNew));
    if (localStorage.getItem("productAdded") === "true") {
      swal
        .fire({
          title: "Producto ha sido cargado. Deseas cargar otro?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `Sí`,
          icon: "success",
          // denyButtonText: `Cancelar`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            window.history.go(-1);
          } else if (result.isDenied) {
            window.history.go(-2);
          }
        });
    } else {
      swal.fire({
        title: localStorage.getItem("productAdded"),
        confirmButtonText: `Aceptar`,
        icon: "error",
        // denyButtonText: `Cancelar`,
      });
    }
  }

  // handle and convert it in base 64
  const handleImage = (e) => {
    const file = e.target.files[0];
    setFileToBase(file);
    console.log(file);
  }

  const setFileToBase = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImage(reader.result);
    }

  }

  function handleChangeSelect(e) {
    var tempera = input.categories.find((temp) => temp === e.target.value);
    console.log(tempera);
    if (!tempera && e.target.value !== "0") {
      data = [...input.categories];
      data.push(e.target.value);
      setInput({ ...input, categories: data });
      var seltempe = document.getElementById("seleccategory");
      console.log(seltempe);
      var strtempe = seltempe.options[seltempe.selectedIndex].text;
      var artempes = document.getElementById("areatempe");
      artempes.value += artempes.value.length > 0 ? ", " + strtempe : strtempe;
      console.log("estas seleccionando:" + data);
    } else alert("La categoría ya fue agregada");
  }


  function handleChangeSelectBrand(e) {
    var brandprod = input.brands.find((brand) => brand === e.target.value);
    console.log(brandprod);
    if (!brandprod && e.target.value !== "0") {
      data = [...input.brands];
      data.push(e.target.value);
      setInput({ ...input, brands: data });
      var selecbrand = document.getElementById("selecbrand");
      console.log(selecbrand);
      var strbrand = selecbrand.options[selecbrand.selectedIndex].text;
      var arbrands = document.getElementById("areamarca");
      arbrands.value += arbrands.value.length > 0 ? ", " + strbrand : strbrand;
      console.log("estas seleccionando:" + data);
    } else alert("La marca ya fue agregada");
  }


  const handleClick = () => {
    console.log("DATA", data)
    let eliminado = data.pop()
    console.log("Elimine", eliminado)
    var artempes = document.getElementById("areatempe");
    // artempes.value -= artempes.value.length > 0 ? ", " - strtempe : strtempe;
    var textoenarea = artempes.value.split(",");
    textoenarea.pop()
    console.log("Text area", textoenarea)
    artempes.value = textoenarea
  }


  const handleClickBrand = () => {
    console.log("DATA", data)
    let eliminado = data.pop()
    console.log("Elimine", eliminado)
    var arbrands = document.getElementById("areamarca");
    // artempes.value -= artempes.value.length > 0 ? ", " - strtempe : strtempe;
    var textoenarea = arbrands.value.split(",");
    textoenarea.pop()
    console.log("Text area", textoenarea)
    arbrands.value = textoenarea
  }



  if (!localStorage.getItem("userInfo"))
    return (
      <>
        <Link to="/loginadmin">
          <h5>Debe estar logueado</h5>
        </Link>
      </>
    );

  return (
    // formulario para agregar producto a la tienda
    <div className="boxform">
      <div>
        <div className="titteam">
          <FaUserCircle />
          Agregar producto
        </div>
        <form onSubmit={handleSubmit} id="form">
          <div className="mb-3">
            <h4>Paso 2</h4>
            Datos del producto
          </div>
          <div className="mb-3">
            <label className="form-label">Seleccione categoría/s</label>
            <select
              className="form-select"
              name="categories"
              value={input.categories}
              onChange={handleChangeSelect}
              id="seleccategory"
            /* required */
            >
              <option key="0" value="0">
                Categorías del producto{" "}
              </option>
              {categoriasAdmin &&
                categoriasAdmin.map((elem) => (
                  <option key={elem.id} value={elem.id}>
                    {elem.category}
                  </option>
                ))}
            </select>
          </div>
          <div className="mb-3">
            <label className="mb-3">Categorías seleccionadas:</label>
            <textarea
              className="form-control"
              id="areatempe"
              readOnly
              rows="1"
              cols="35"
            /><div className="btn btn-outline-success searchbut" onClick={handleClick}> borrar </div>
          </div>

          <div className="mb-3">
            <label className="form-label">Seleccione marca</label>
            <select
              className="form-select"
              name="brands"
              value={input.brands}
              onChange={handleChangeSelectBrand}
              id="selecbrand"
            /* required */
            >
              <option key="0" value="0">
                Marca del producto{" "}
              </option>
              {brandsAdmin &&
                brandsAdmin.map((elem) => (
                  <option key={elem.id} value={elem.id}>
                    {elem.brand}
                  </option>
                ))}
            </select>
          </div>
          <div className="mb-3">
            <label className="mb-3">Marca seleccionadas:</label>
            <textarea
              className="form-control"
              id="areamarca"
              readOnly
              rows="1"
              cols="35"
            /><div className="btn btn-outline-success searchbut" onClick={handleClickBrand}> borrar </div>
          </div>

          <div className="mb-3">
            <label className="form-label">Producto </label>
            <input
              className="form-control"
              className={errors.name && "danger"}
              type="text"
              placeholder="producto"
              name="name"
              onChange={handleInputChange}
              value={input.name}
            ></input>
            {errors.name && <p className="danger">{errors.name}</p>}
          </div>
          <div className="mb-3">
            <label className="form-label">Descripción </label>
            <input
              className="form-control"
              type="text"
              placeholder="Descripcion"
              name="description"
              onChange={handleInputChange}
              value={input.description}
            ></input>
          </div>
          <div className="mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              name="exist"
              onChange={handleInputChange}
              value={input.exist}
            ></input>
            <label className="form-check-label">Hay stock</label>
          </div>
          <div className="mb-3">
            <label className="form-label">Precio </label>
            <input
              className="form-control"
              className={errors.price && "danger"}
              type="numbre"
              name="price"
              onChange={handleInputChange}
              value={input.price}
            ></input>
            {errors.price && <p className="danger">{errors.price}</p>}
          </div>
          <div className="mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              name="isofert"
              onChange={handleInputChange}
              value={input.isofert}
            ></input>
            <label className="form-check-label">Es Oferta </label>{" "}
          </div>
          <div className="form-outline mb-4">
            <label className="form-label" htmlFor="form4Example2">Image</label>
            <input onChange={handleImage} type="file" id="formupload" name="image" className="form-control" />
          </div>
          <div className="mb-3">
            <label className="form-label">Tipo de unidad </label>
            <input
              className="form-control"
              type="text"
              name="units"
              onChange={handleInputChange}
              value={input.units}
            ></input>
          </div>
          <div className="mb-3">
            <label className="form-label">Stock unidades</label>
            <input
              className="form-control"
              type="number"
              name="stockunits"
              onChange={handleInputChange}
              value={input.stockunits}
            ></input>
          </div>
          <div className="mb-3">
            <label className="form-label">Capacidad de unidad</label>
            <input
              className="form-control"
              type="text"
              name="capacity"
              onChange={handleInputChange}
              value={input.capacity}
            ></input>
          </div>
          <div className="mb-3">
            <label className="form-label">Tipo de capacidad</label>
            <input
              className="form-control"
              type="text"
              name="capacityunit"
              onChange={handleInputChange}
              value={input.capacityunit}
            ></input>
          </div>
          <div>
            <label className="form-label">Minimo de compra </label>
            <input
              className="form-control"
              type="number"
              name="minunit"
              onChange={handleInputChange}
              value={input.minunit}
              step={0.250}
              min={0.250}
              max={100}
            ></input>
          </div>
          <div>
            <label className="form-label">Salto de compra</label>
            <input
              className="form-control"
              type="number"
              name="stepunit"
              max={1}
              step={0.250}
              min={0.250}
              onChange={handleInputChange}
              value={input.stepunit}
            ></input>
          </div>{" "}
          <div>
            <label className="form-label">Peso</label>
            <input
              className="form-control"
              type="number"
              name="weight"
              onChange={handleInputChange}
              value={input.weight}
            ></input>
          </div>{" "}
          <div>
            <label className="form-label">Alto</label>
            <input
              className="form-control"
              type="number"
              name="height"
              onChange={handleInputChange}
              value={input.height}
            ></input>
          </div>{" "}          <div>
            <label className="form-label">Ancho</label>
            <input
              className="form-control"
              type="number"
              name="width"
              onChange={handleInputChange}
              value={input.width}
            ></input>
          </div>{" "}
          <div className="addback">
            <button className="btn btn-success" type="submit">
              Agregar
            </button>
            <button
              className="btn btn-secondary"
              type="reset"
              onClick={() => {
                window.history.go(-2);
              }}
            >
              Volver
            </button>
          </div>
        </form>

        <div id="regis" className="logsub">
          {/*  <Link to={linkto}>{texto}</Link> */}
        </div>
      </div>
    </div>
  );
};


export default ProductForm;
