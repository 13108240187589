import "../ppv/styles.css"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { REACT_APP_API } from "../../store/consts/consts";
import VerTorneo from "../../components/VerTorneo"
import Spinner from "../../components/spinner"
import estadio from "../../images/basquet.jpg"

const PayPerView = () => {

    const [input, setInput] = useState({
        email: "",
        dias: 0,
    });
    const [ipvalido, setIpValido] = useState(false)
    const [isloading, setIsloading] = useState(true)
    const [dias, setDias] = useState(0)
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Comprar")


    const [ipAddress, setIPAddress] = useState('')

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {
                setIPAddress(data.ip)
            })
            .catch(error => console.log(error))
    }, []);


    const disableButton = () => {
        setButtonDisabled(true);
        setBtnText("Comprando...")
    };

    const enableButton = () => {
        setButtonDisabled(false);
    };


    const PagoIp = async () => {
        await axios.get(`${REACT_APP_API}payper/` + ipAddress)
            .then((res) => {
                setIpValido(res.data.active)
                setDias(res.data.dias)
                setIsloading(false)
                return true
            })
            .catch((error) => {
                setIsloading(false)
                return false
            })
    }

    const prod1 = {
        email: input.email,
        dias: 1,
        ip: ipAddress,
        title: ipAddress.toString() + " Partido BC vs MI",
        monto: 800,
        active: false,
        idorder: "",
        compras: 1
    }

    PagoIp()

    console.log(ipAddress, ipvalido)

    return <>{/* ipvalido && */ !isloading ? ipvalido ? <>
        <VerTorneo />
    </> :
        <div className="containerppv">
            {/*<div className="boxaviso">
                <div className="boxtitle">Eventos Pay Per View</div>
                <div className="textoppv">Muy pronto podrás ver los mejores eventos de básquetbol...<br /><br />EN VIVO!</div>

                {/* <div style={{ paddingBottom: 15 }}><button style={{ borderRadius: 5, backgroundColor: "blueviolet", color: "white" }} onClick={async () => await axios.post(`${REACT_APP_API}mp/pagoppv`, prod1).then((res) => window.location.href = res.data.response.body.init_point)}>Acceso 1 dia</button></div> */}
            {/*
                <div className="boxip"><span className="h4">{ipAddress.toString()}</span></div>
            </div> */}
            <div className="row row-cols-1 row-cols-md-2 d-flex center-flex aligns-items-center justify-content-center">
                <div className="card" /* style={{ "width": "18rem;" }} */>
                    <img src={estadio} className="card-img-top" alt="..." />
                    <div className="card-body">
                        <h5 className="card-title">Fecha 1 - 17/03</h5>
                        <p className="card-text ipview"><b>Bragado Club</b> vs <b>Los Millonarios</b></p>
                        <button className="btn btn-primary" disabled={isButtonDisabled} onClick={
                            async () => {
                                disableButton()
                                await axios.post(`${REACT_APP_API}mp/pagoppv`, prod1).then((res) => window.location.href = res.data.response.body.init_point)
                            }
                        }>{btnText}</button>
                        <hr />
                           <div className="boxip"><span className="ipview">Tu IP es: {ipAddress.toString()}</span></div>
                           <div style={{ paddingBottom: 15 }}>
                                <button style={{ borderRadius: 5, backgroundColor: "orange", color: "white" }} onClick={() => window.location.href = '../payper'}>Si ya pagó, actualice aquí</button>
                            </div>
                    </div>
                </div>
            </div>
        </div> : <Spinner />} </>
}

export default PayPerView