import * as Yup from 'yup'
import { Formik, Form } from 'formik';
import Swal from 'sweetalert2'

const Contact = () => {
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const emailRegExp = /^[a-zA-Z0–9._-]+@[a-zA-Z0–9.-]+\.[a-zA-Z]{2,4}$/;

    const schema = Yup.object().shape({
        phoneNumber: Yup.string().required("Tu celular es requerido").matches(phoneRegExp, 'Phone number is not valid').max(13, "Máximo 13 caracteres numericos"),
        name: Yup.string().required("Tu nombre es requerido"),
        email: Yup.string().matches(emailRegExp, 'Email is not valid').required("Tu email es requerido"),
        message: Yup.string().required("Por favor ingrese un mensaje"),
    });

    return (<div className="container mt-5">
        <h2
            className="text-center text-uppercase m-5"
            style={{ letterSpacing: "5px", fontWeight: "ligher" }}
        >
            Formulario de contacto
        </h2>
        <Formik
            validationSchema={schema}
            initialValues={{ name: "", email: "", phoneNumber: "", message: "" }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
                console.log('Logging in', values);
                const sendMail = {
                    name: values.name,
                    email: values.email,
                    message: values.message,
                    cellphone: values.phoneNumber,
                }

                    console.log()
                    const objConf = {
                        business: values.name,
                        userid: localStorage.getItem("userAdded")
                    }
                    const objGroup = {
                        category: "Default",
                        description: "Categoría por defecto",
                        undelete: true,
                        userid: localStorage.getItem("userAdded")
                    }
                    // await dispatch(configAdd(objConf))
                    // await dispatch(cateAdd(objGroup))
                     Swal.fire({
                        title: "Formulario!",
                        html: "Usuario registrado con éxito!",
                        icon: "success"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            resetForm({ values: "" })
                            // navigate("/", { replace: true });
                        }
                    });
                setSubmitting(false);

            }}
        >
            {
                props => {
                    const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit
                    } = props;

                    return (
                        <Form onSubmit={handleSubmit}
                            className="border rounded p-4"
                            style={{ maxWidth: "80%", margin: "auto" }}
                        >
                            <label className='form-label' htmlFor='Usuario'>Nombre</label>
                            <input
                                className='form-control'
                                id='name'
                                name='name'
                                type='text'
                                placeholder='Ingresa tu Nombre'
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.name ? <p className="error">
                                {errors.name && touched.name && errors.name}
                            </p> : ""}
                            <label className='form-label' htmlFor='Usuario'>Celular</label>
                            <input
                                className='form-control'
                                id='phoneNumber'
                                name='phoneNumber'
                                type='text'
                                placeholder='Tu WA formato: 5491144445555'
                                value={values.phoneNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.phoneNumber ? <p className="error">
                                {errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
                            </p> : ""}
                            <label className='form-label' htmlFor='Usuario'>Email</label>
                            <input
                                className='form-control'
                                id='email'
                                name='email'
                                type='text'
                                placeholder='Ingresa tu Email'
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.email ? <p className="error">
                                {errors.email && touched.email && errors.email}
                            </p> : ""}
                            <label className='form-label' htmlFor='password'>Mensaje</label>
                            <input
                                className='form-control'
                                id='message'
                                name='message'
                                type='text'
                                placeholder='Ingresa tu mensaje'
                                value={values.message}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.message ? <p className="error">
                                {errors.message && touched.message && errors.message}
                            </p> : ""}
                            <div className='d-flex justify-content-around'>
                                <button type='sumit' disabled={isSubmitting}>Send message</button>
                            </div>
                        </Form>
                    );
                }
            }
        </Formik>
    </div>
    )
}

export default Contact