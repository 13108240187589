import React, { useState, useEffect, lazy, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
// import ProductGrid from "../../components/productGrid";
import { getAllProductsCat } from "../../store/actions/products";
// import GridCart from "../carrito/gridCart";
// import Paso1 from '../../components/checkout/paso1'
import Spinner from "../../components/spinner";

import AbouUs from "../../components/AboutUs";
import NavBar from "../../components/navBar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { MdShoppingCart } from "react-icons/md";

const ProductGrid = lazy(() => import("../../components/productGrid"))
const GridCart = lazy(() =>import("../carrito/gridCart"))

const renderLoader = () => <p>Loading</p>;

export default function Shop() {
  const dispatch = useDispatch();
  const productos = useSelector((state) => state.Product.allProducts);
  const mensajeno = useSelector((state) => state.Product.alertprod);
  const [viewHelp, setViewHelp] = useState(true);

  useEffect(async () => {
    // console.log('entrando al home')
    await dispatch(getAllProductsCat()); // Pido todos los productos

    // sortCategories(productos)
  }, []);

  const [loading] = useState(false);
  /*    const productos = useSelector(state => state.Product.allProducts); */
  const verCarro = useSelector((state) => state.Carrito.guestCartProd);
  //     console.log(productos," - ", verCarro)
  const configs = useSelector((state) => state.User.configsAdmin);
  const exist = productos.filter(function (productos) {
    return productos.exist === true;
  });

  return (
    <>
      <div className="container"/*  style={{ marginTop: "5.25em" }} */>
        <h3>Productos disponibles</h3><h5>para compra online</h5>
        <div style={{ width: "100%" }}>
          {!configs.horario ? (
            ""
          ) : (
            <Alert variant="filled" severity="warning">
              <AlertTitle>ATENCION</AlertTitle>
              {configs.horario}
            </Alert>
          )}
        </div>
        {exist.length ? (
          <>
            {/*             {viewHelp === true ? (
              <Alert
                onClose={() => {
                  setViewHelp(false);
                }}
                variant="filled"
                severity="info"
              >
                <AlertTitle>INFO</AlertTitle>
                Luego de AGREGAR producto/s, puede modificar las cantidades en{" "}
                <MdShoppingCart style={{ width: "20px", height: "20px" }} />
                { console.log(viewHelp) }
              </Alert>
            ) : (
              ""
            )} */}
            <Suspense fallback={renderLoader()}>
              <ProductGrid loading={loading} items={exist} />
            </Suspense>
          </>
        ) : (
          <div>
            {
              /* productos.length &&  */ mensajeno.length ? (
                <Alert severity="warning">
                  <AlertTitle>No se encontró el producto</AlertTitle>Intente
                  escribirlo de otra forma
                </Alert>
              ) : (
                <Spinner />
              )
            }{" "}
          </div>
        )}{" "}
       {/*  {verCarro.length ? <Suspense fallback={renderLoader()}><GridCart /></Suspense> : ""} */}
      </div>

    </>
  );
}
