const initialState = {
  allBrands: [],
  brandsAdmin: [],
  brandAdminGet: {},
};

export default function categoryReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_ALL_BRANDS":
      return {
        ...state,
        allBrands: action.payload,
      };
    case "BRAND_ADMIN_ADD":
      return {
        ...state,
        brandsAdmin: state.brandsAdmin.concat(action.payload),
      };
    case "BRAND_ADMIN_GET":
      return {
        ...state,
        brandAdminGet: action.payload,
      };

    case "DELETE_BRAND":
      return {
        ...state,
        allBrands: state.allBrands.filter(
          (c) => c.id !== action.payload
        ),
      };

    case "UPDATE_BRAND":
      return {
        ...state,
        brandAdminGet: action.payload,
      };
    default:
      return state;
  }
}
