import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player'
import axios from 'axios'
import { REACT_APP_API } from "../../store/consts/consts";
import img01 from "../../images/banner03.jpg"
import VideoPlay from '../VideoPlay';


const VerTorneo = () => {

    const [ipAddress, setIPAddress] = useState('')
    const [ipvalido, setIpValido] = useState(false)
    const [isloading, setIsloading] = useState(true)
    const [dias, setDias] = useState(0)


    useEffect(() => {
        setIsloading(true)
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {
                setIPAddress(data.ip)
            })
            .catch(error => console.log(error))
    }, []);

    const PagoIp = async () => {
        await axios.get(`${REACT_APP_API}payper/` + ipAddress)
            .then((res) => {
                setIpValido(res.data.active)
                setDias(res.data.dias)
                setIsloading(false)
                return true
            })
            .catch((error) => {
                setIsloading(false)
                return false
            })
    }

    PagoIp()

    return <>
        {ipvalido && !isloading ? <>
            <VideoPlay />   
            {/* Dias contratados { dias } */}
        </> : <> {ipAddress} IP no permitido
            <div style={{ paddingBottom: 15 }}>
                <button style={{ borderRadius: 5, backgroundColor: "green", color: "white" }} onClick={() => window.open("https://api.whatsapp.com/send?phone=542342513085", '_blank') /* window.location.href = "https://api.whatsapp.com/send?phone=542342462243" */}>Contacto 2342 513085</button>
            </div></>
        }
    </>
}

export default VerTorneo