import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateBrand } from "../../store/actions/brands";
import swal from "sweetalert2";
// import "./login.css";
// import Dashboard from '../../views/admin/dashboard'
import { FaUserCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import BrandImage from "./BrandImage";

export function validatecate(input) {
  // var emailPattern = /\S+@\S+\.\S+/; // Expresion Regular para validar Emails.

  let errors = {};
  if (!input.name) {
    errors.name = "Por favor, Ingrese nombre de categoría";
  }
  return errors;
}

const BrandFormMod = () => {
  const dispatch = useDispatch();
  const userAdmin = useSelector((state) => state.User.userDetail);
  const brandAdmin = useSelector((state) => state.Brand.brandAdminGet);
  const [errors, setErrors] = React.useState({});

  const [input, setInput] = React.useState({
    name: "",
    description: "",
    image: ""
  });

  useEffect(() => {
    setInput({
      name: brandAdmin.brand,
      description: brandAdmin.description,
      image: brandAdmin.image
    });
    console.log("BRANDADMIN", brandAdmin);
  }, [brandAdmin]);

  const handleInputChange = function (e) {
    // validate(e.target.name,e.target.value)
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    setErrors(
      validatecate({
        ...input,
        [e.target.name]: e.target.value,
      })
    );
  };

  async function handleSubmit(e) {
    // funcion que debe solicitar usuario logueado
    e.preventDefault();
    let brandNewImage = ""
    !localStorage.getItem("urlImage") ? brandNewImage = input.image : brandNewImage = localStorage.getItem("urlImage")
    const updBrand = {
      id: brandAdmin.id,
      brand: input.name,
      description: input.description,
      image: brandNewImage
    };

    await dispatch(updateBrand(updBrand,userAdmin.token));
    if (localStorage.getItem("brandUpdated") === "true") {
      swal
        .fire({
          title: "Marca modificada",
          confirmButtonText: `Ok`,
          icon: "success",
          // denyButtonText: `Cancelar`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            setInput({
              name: "",
              description: "",
            });
            window.history.go(-1);
          }
        });
    } else {
      swal.fire({
        title: localStorage.getItem("brandUpdated"),
        confirmButtonText: `Ok`,
        icon: "error",
        // denyButtonText: `Cancelar`,
      });
    }
  }

  if (!localStorage.getItem("userInfo")) return <Link to='/loginadmin'><h5>Debe estar logueado</h5></Link>
    if (!brandAdmin) return <> Cargando... </> 

  return (
    // formulario para agregar producto a la tienda
    <div className="boxcontainer">
      <div className="boxteam">
        <div className="titteam">
          <FaUserCircle />
          Modificar marca
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              Marca
              <input
                className={errors.name && "danger"}
                type="text"
                placeholder="marca"
                name="name"
                onChange={handleInputChange}
                value={input.name}
              ></input>
            </label>
            {errors.name && <p className="danger">{errors.name}</p>}
          </div>
          <div>
            <label>
              Descripción
              <input
                type="text"
                placeholder="Descripcion"
                name="description"
                onChange={handleInputChange}
                value={input.description}
              ></input>
            </label>
          </div>
          <div class="mb-3">
            <label class="form-label">cambiar logo</label>
            <img
              /* class="form-control" */
              src={input.image}
              alt={input.name}
                  width="400px"
                  height="auto"
            />
            <BrandImage />
            {/*             <input
              class="form-control"
              type="text"
              name="image"
              onChange={handleInputChange}
              value={input.image}
              readOnly
            ></input> */}
            </div>
          <div>
            <button className="btn btn-success" type="submit">
              Modificar
            </button>
            <button
              className="btn btn-secondary"
              type="reset"
              onClick={() => {
                window.history.go(-1);
              }}
            >
              Volver
            </button>
          </div>
        </form>
        <div id="regis" className="logsub"></div>
      </div>
    </div>
  );
};

export default BrandFormMod;
