import React from "react";
import products from "../../images/hardware.png"
import desarrollo from "../../images/desarrollodesoft.jpg"
import Helmet from "react-helmet"
import "./styles.css"
import { getConfig } from "../../store/actions/users";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

export default function Home() {

  const dispatch = useDispatch()
  const configsAdmin = useSelector((state) => state.User.configsAdmin);

  useEffect(async() => {
    if (!configsAdmin.id)
    await dispatch(getConfig(1)); // Pido los datos de configuración
  },[])

  return (
    <>
      <Helmet>
        <title>Servicios Informáticos Bragado</title>
        <meta name="description" content="Sitio Web Oficial de productos de computación y tecnología." />
        <meta name="keywords" content="bragado, servicios, informaticos" />
        <meta property="og:url" content="sib-2000.com.ar" />
        <meta property="og:site_name" content="Servicios Informáticos Bragado" />
        <meta property="og:locale" content="es_ES" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://sib-2000.com.ar/logos/logosib.png" />
        <meta property="og:description" content="Sitio Web Oficial de productos de computación y tecnología." />
        <link rel="canonical" href="://sib-2000.com.ar" />

      </Helmet>
      <h1 className="h1">Productos y Servicios</h1><h3 className="h3"> de computación y tecnología</h3>
      <h5 className="h5">Hardware</h5>
      <h6 className="h6">teclados, mouses, joystics</h6>
      <img src={products} alt="SIB 2000 hardware" title="hardware" style={{ width: "100%", maxWidth: "467px", height: "auto" }} />
      <h5 className="h5">Software</h5>
      <h6 className="h6">Sistemas y aplicaciones</h6>
      <img src={desarrollo} alt="SIB 2000 software" title="software" style={{ width: "100%", maxWidth: "500px", height: "auto" }} />
      <h5 className="h5">Websites</h5>
      <h6 className="h6">Aplicaciones y sitios web</h6>
      <img src={products} alt="SIB 2000 websites" title="websites" style={{ width: "100%", maxWidth: "500px", height: "auto", borderRadius: "15%" }} />
    </>
  );
}
